<template>
  <div style="background: #ffffff" class="pb-5" id="videoSection">
    <v-row>
      <v-col>
        <div class="heading">Sign Up For Wizfit Challenge Now</div>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="$vuetify.breakpoint.smAndUp">
      <v-col cols="12" sm="8">
        <v-row>
          <v-col cols="12" class="py-5">
            <div @click="playVideo()" style="cursor: pointer">
              <video
                poster="@/assets/publicDonationPage/timelineEvent/introd1.png"
                style="
                  width: 144%;
                 /* height: 350px;**/
                  object-fit: contain;
                  background: rgba(29, 29, 29, 0.2);
                "
              >
                <source
                  src="https://video.harlemwizardsinabox.com/introductionvideo.mp4"
                />
              </video>
              <img
                src="@/assets/play.png"
                :width="$vuetify.breakpoint.smAndUp ? '80' : '60'"
                alt=""
                class="playImg"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
     <v-row no-gutters v-if="$vuetify.breakpoint.xsOnly">
      <v-col cols="12" sm="8">
        <v-row>
          <v-col cols="12" class="py-5">
            <div @click="playVideo()" style="cursor: pointer">
              <video
                poster="@/assets/publicDonationPage/timelineEvent/introd1.png"
                style="
                  width: 100%;
                  height: 185px;
                  object-fit: contain;
                  background: rgba(29, 29, 29, 0.2);
                "
              >
                <source
                  src="https://video.harlemwizardsinabox.com/introductionvideo.mp4"
                />
              </video>
              <img
                src="@/assets/play.png"
                :width="$vuetify.breakpoint.smAndUp ? '80' : '60'"
                alt=""
                class="playImgMob"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <video-player-modal></video-player-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "VideoSection",
  components: {
    VideoPlayerModal: () => import("./VideoSectionViewerWizfit/VideoSectionViewerWizfit"),
  },
  data() {
    return {
      student: null,
      videoList: [],
      videoToPlay: {},
      btnSelector: true,
      videoViewer: {
        flag: false,
        src: "",
        type: "video",
        obj: {},
      },
    };
  },
  created() {
    this.$root.$refs.videoSection = this;
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({ toggleVideoModal: "videoSection/toggleModal" }),
    playVideo() {
      this.toggleVideoModal({
        show: true,
        obj: "https://video.harlemwizardsinabox.com/introductionvideo.mp4",
        src: "https://video.harlemwizardsinabox.com/introductionvideo.mp4",
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.watchAgain {
  -webkit-transition: 5s all;
  -webkit-transition: 5s all;
  -webkit-transition-delay: 5s;
  -moz-transition: 5s all;
  -moz-transition-delay: 5s;
  -ms-transition: 5s all;
  -ms-transition-delay: 5s;
  -o-transition: 5s all;
  -o-transition-delay: 5s;
  transition: 5s all;
  transition-delay: 5s;
}


 .playImg {
  position: absolute;
  right: 19%;
  margin-top: -50%;
}
 

.playImgMob {
  position: absolute;
  right: 35%;
  margin-top: 70px;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    background-color: #2c1963;
    width: 30px;
    height: 3px;
    margin-top: 15px;
  }
  
}
</style>
